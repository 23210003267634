import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchData(ctx, queryParams) {
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .post('/settings/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        updateData(ctx, queryParams){
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .post('/settings/v1/update',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        }
    }
}