<template>
    <b-card no-body class="p-3">
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
        <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >
            <b-tabs v-if="settings" v-model="tabIndex">
                <b-tab v-for="setting in settings" :key="'tab-'+setting.module" :title="setting.module">
                <template v-if="setting.settings.length > 0">
                    <validation-provider
                        v-for="setts in setting.settings"
                        :key="'set-tab'+setting.module+'-'+setts.slug"
                        #default="validationContext"
                        :name="setts.slug"
                        :rules="(setts.is_required?'required':'min:0')"
                        >
                        <b-form-group
                            :label="setts.title"
                            :label-for="setts.slug"
                        >
                            <b-form-input
                             v-if="setts.type=='text' || setts.type=='password'"
                            :id="setts.slug"
                            v-model="setts.value"
                            :type="setts.type"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            autocomplete="false"
                            :placeholder="setts.title"
                          
                            />
                            <b-form-textarea
                             v-if="setts.type=='textarea'"
                            :id="setts.slug"
                            v-model="setts.value"
                         
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            :placeholder="setts.title"
                          
                            />
                            
            
                            <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </template>   
                </b-tab>
            

            </b-tabs>
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Submit
              </b-button>
              
            </div>
        </b-form>
        </validation-observer>
        
    </b-card>
</template>
<script>
//send-grid user, pass
import {
   BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab
 } from 'bootstrap-vue'

 import {
  BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BListGroup,BListGroupItem
 } from 'bootstrap-vue'
 import { ValidationProvider, ValidationObserver } from 'vee-validate'
 import { ref } from '@vue/composition-api'
 import { required, alphaNum, email, password,confirmed } from '@validations'
 import formValidation from '@core/comp-functions/forms/form-validation'
 import Ripple from 'vue-ripple-directive'
 import store from '@/store'
 import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
 import { useToast } from 'vue-toastification/composition'
 import { onUnmounted } from '@vue/composition-api'
 import settingsStoreModule from './settingsStoreModule'
 import useSettings from './useSettings'
export default {
    components: {
        BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab,
        BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BListGroup,BListGroupItem,
        ValidationProvider, ValidationObserver
    },
    directives: {
     Ripple,
   },
   setup() {
        const PREMISSIONS_SETTINGS_STORE_MODULE_NAME = 'permissions-settings'
        if (!store.hasModule(PREMISSIONS_SETTINGS_STORE_MODULE_NAME)) store.registerModule(PREMISSIONS_SETTINGS_STORE_MODULE_NAME, settingsStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PREMISSIONS_SETTINGS_STORE_MODULE_NAME)) store.unregisterModule(PREMISSIONS_SETTINGS_STORE_MODULE_NAME)
        })
        const {
            settings,
            onSubmit,
            resetData,
            tabIndex
        } = useSettings();
        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation(resetData)
        return {
            settings,
            onSubmit,   
            tabIndex,

            refFormObserver,
            getValidationState,
            resetForm,
        }
   }
    
}
</script>>