import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'


// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useSettings() {
    const toast = useToast();
    const settings = ref([]);
    const tabIndex = ref(0);
    const getData = ()=>{
        store.dispatch('permissions-settings/fetchData')
    .then(response => {
        
        settings.value  = response.data.data
        console.log('response',settings.value);
      
      
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      });

    }
    getData();
    
      const onSubmit = () => {
        console.log('settings',settings.value);
        store.dispatch('permissions-settings/updateData', settings.value)
        .then((res) => {
            console.log('submit',res.data);
            if(res.data.success){
            toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                title: `Success`,
                icon: 'BellIcon',
                variant: 'success',
                text: `${res.data.message}`,
                },
            });
            tabIndex.value = 0;
            getData();
           
            }else{
                tabIndex.value = 0;
            toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                title: `Error`,
                icon: 'BellIcon',
                variant: 'danger',
                text: `${res.data.message}`,
                },
            });
        
            
            }
        
        })
      }
      const resetData = () => {
            //Data.value = JSON.parse(JSON.stringify(blankData))
        }

    return {
        tabIndex,
        settings,
        onSubmit,
        resetData

    }
}